import { render, staticRenderFns } from "./NotInstitutions.html?vue&type=template&id=5f7838bd&scoped=true&"
import script from "./NotInstitutions.vue?vue&type=script&lang=js&"
export * from "./NotInstitutions.vue?vue&type=script&lang=js&"
import style0 from "./NotInstitutions.scss?vue&type=style&index=0&id=5f7838bd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7838bd",
  null
  
)

export default component.exports