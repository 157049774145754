<template src="./NotInstitutions.html"></template>

<script>
export default {
  name: "notInstitutions",
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" src="./NotInstitutions.scss" scoped></style>